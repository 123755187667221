import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import LogoLetters from './images/letters_green.svg';
import DashboardIcon from './images/dashboard.svg';
import OverviewIcon from './images/control-panel.svg';
import MapIcon from './images/map.svg';

const Sidebar = ({ signOut }) => {
    return (
        <aside className="sidebar">

            <nav className="sidebar-menu">
                <Link to="/control-panel" className="menu-item">
                    <img src={OverviewIcon} alt="Control Panel" />
                    Control Panel
                </Link>
                <Link to="/hydrants-dashboard" className="menu-item" title="Hydrants ControlPanel">
                    <img src={DashboardIcon} alt="ControlPanel" />
                    <span>Dashboard</span>
                </Link>
                <Link to="/map" className="menu-item">
                    <img src={MapIcon} alt="Map" />
                    Map
                </Link>
            </nav>
        </aside>
    );
};

export default Sidebar;
